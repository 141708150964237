import { useEffect, useState } from "react";
// 判断设备显示宽度
export const verifyEquipment = () => {
  // 1 -> pc,2 -> pad,3 -> mobile
  // const width = document.body.clientWidth;
  // return width >= 1200 ? 1 : (
  //   width >= 768 && width < 1200 ? 2 : 3
  // )
  // return width >= 1200 ? false : true;

  let sUserAgent = navigator.userAgent.toLowerCase();
  let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
  let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
  let bIsMidp = sUserAgent.match(/midp/i) == "midp";
  let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
  let bIsAndroid = sUserAgent.match(/android/i) == "android";
  let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
  let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  let ismobile = 0;
  if (
    bIsIpad ||
    bIsIphoneOs ||
    bIsMidp ||
    bIsUc7 ||
    bIsUc ||
    bIsAndroid ||
    bIsCE ||
    bIsWM
  ) {
    ismobile = true;
  } else {
    ismobile = false;
  }
  return ismobile;
};
// 自定义hook设置页面标题
export const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

//是否移动端进入官网页面, 判断是否继续加载官网页面
export const downloadPage = (pathname) => {
  // let system = localStorage.getItem("hotsign_system");
  // if (system && system === "pc") return;
  // if (system && system === "mobile") {
  //   window.location.href = `https://www.huosign.com.cn/mobappDownload.html`;
  //   return;
  // }
  localStorage.removeItem("hotsign_system");
  let _system = verifyEquipment();
  //如果是活动页
  if (_system && pathname.indexOf("mob") === -1) {
    // localStorage.setItem("hotsign_system", "mobile");
    window.location.href = `https://www.huosign.com/mobile/appdownload`;
    return;
  }
  // localStorage.setItem("hotsign_system", "pc");
};

//是否滚动到底部
export const slideToBottom = (e, fun, distance = 20) => {
  const { clientHeight, scrollHeight, scrollTop } = e;
  const _isBottom = scrollTop + clientHeight + distance > scrollHeight;
  // console.log(scrollTop + clientHeight + distance, scrollHeight);
  if (_isBottom && fun) fun();
  if (_isBottom) {
    // console.log("触底了");
  }
  // console.log(_isBottom)
  return _isBottom;
};
//是否是h5页
export const isH5Sharepage = (path) => {
  if (
    path.indexOf("mobile") !== -1 ||
    path.indexOf("trader") !== -1 ||
    path.indexOf("huosign-api") !== -1 ||
    (path.indexOf("userPolicy") !== -1 &&
      document.documentElement.clientWidth < 1200)
  ) {
    return true;
  } else {
    return false;
  }
};
// 数组中随机取几个元素
export const getRandomArrayElements = (arr, count) => {
  var shuffled = arr.slice(0),
    i = arr.length,
    min = i - count,
    temp,
    index;
  while (i-- > min) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(min);
};

//处理pdf
export const showFile = (blob, type = "application/pdf") => {
  var newBlob = new Blob([blob], { type });

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  // link.download = "quide-hbjm.pdf";
  link.target = "_new";
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 100);
};

//计算日期差
export const compare = (sDate1, sDate2) => {
  //sDate1和sDate2是2006-12-18格式  把所有的 - 替换成 /
  var dateSpan;
  sDate1 = Date.parse(sDate1.replace(/-/g, "/"));
  sDate2 = Date.parse(sDate2.replace(/-/g, "/"));
  dateSpan = sDate2 - sDate1;
  return dateSpan;
};
