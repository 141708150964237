import React from "react";
const MobShare = React.lazy((_) =>
  import("../pages/Mobile/MobShare/ShareStartPage")
);
const ShareRecordList = React.lazy((_) =>
  import("../pages/Mobile/MobShare/ShareRecordListPage")
);
const ShareRulePage = React.lazy((_) =>
  import("../pages/Mobile/MobShare/ShareRulePage")
);
const ShareRegPage = React.lazy((_) =>
  import("../pages/Mobile/MobShare/ShareRegPage")
);
const MobServicePage = React.lazy((_) =>
  import("../pages/Mobile/MobServicePage")
);
const MobArticleShare = React.lazy((_) =>
  import("../pages/Mobile/MobArticleShare")
);
const MobUserAgreement = React.lazy((_) =>
  import("../pages/Mobile/MobUserAgreement")
);
const MobPrivacyPolicy = React.lazy((_) =>
  import("../pages/Mobile/MobPrivacyPolicy")
);
const MobDisclaimer = React.lazy((_) =>
  import("../pages/Mobile/MobDisclaimer")
);
const MobTVShare = React.lazy((_) => import("../pages/Mobile/MobTVShare"));
const MobAppDownLoad = React.lazy((_) =>
  import("../pages/Mobile/MobAppDownLoad")
);
const MobProductFeatures = React.lazy((_) =>
  import("../pages/Mobile/MobProductFeatures")
);
const MobTopicShare = React.lazy((_) =>
  import("../pages/Mobile/MobTopicShare")
);
const MobLotteryPage = React.lazy((_) =>
  import("../pages/Mobile/MobGfgWxw/MobLotteryPage")
);
const MobGfgRulePage = React.lazy((_) =>
  import("../pages/Mobile/MobGfgWxw/MobGfgRulePage")
);
const MobGfgRegPage = React.lazy((_) =>
  import("../pages/Mobile/MobGfgWxw/MobGfgRegPage")
);
const MobTradingContest = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContest/MobContestStartPage")
);
const MobContestStartPageS6 = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContestS6/MobContestStartPageS6")
);
const MobContestStartPageS7 = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContestS7/MobContestStartPageS7")
);
const MobContestStartPageS8 = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContestS8/MobContestStartPageS8")
);
// const MobContestRulePage = React.lazy((_) =>
//   import("../pages/Mobile/MobTradingContest/MobContestRulePage")
// );
const MobContestRulePageS6 = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContestS6/MobContestRulePageS6")
);
const MobContestRulePageS7 = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContestS7/MobContestRulePageS7")
);
const MobContestRulePageS8 = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContestS8/MobContestRulePageS8")
);
const MobRankingPage = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContest/MobRankingPage")
);
const MobRankingPageS6 = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContestS6/MobRankingPageS6")
);
const MobRankingPageS7 = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContestS7/MobRankingPageS7")
);
const MobRankingPageS8 = React.lazy((_) =>
  import("../pages/Mobile/MobTradingContestS8/MobRankingPageS8")
);
const MobFramerPage = React.lazy((_) =>
  import("../pages/Mobile/MobFramer/MobFramerPage")
);
const MobFramerRulePage = React.lazy((_) =>
  import("../pages/Mobile/MobFramer/MobFramerRulePage")
);
const MobCommunityRulePage = React.lazy((_) =>
  import("../pages/Mobile/MobCommunityRulePage")
);
const MobHXShowtimePage = React.lazy((_) =>
  import("../pages/Mobile/MobHXShowtimePage")
);
const MobLetter = React.lazy((_) => import("../pages/Mobile/MobLetter"));
const MobNewuser = React.lazy((_) => import("../pages/Mobile/MobNewuser"));
const MobCoinClose = React.lazy((_) =>
  import("../pages/Mobile/MobCoinClosePage")
);
const MobOpen = React.lazy((_) => import("../pages/Mobile/MobOpen"));
const MobOpenRulePage = React.lazy((_) =>
  import("../pages/Mobile/MobOpenRulePage")
);
const MobCertificateDesc = React.lazy((_) =>
  import("../pages/Mobile/MobCertificateDesc")
);
const MobDealCoursePage = React.lazy((_) =>
  import("../pages/Mobile/MobDealCoursePage")
);
const MobHuoXinPage = React.lazy((_) =>
  import("../pages/Mobile/MobHuoXinPage")
);
const CampusAPage = React.lazy((_) =>
  import("../pages/Mobile/CampusAmbassador/CampusAPage")
);
const CampusARegPage = React.lazy((_) =>
  import("../pages/Mobile/CampusAmbassador/CampusARegPage")
);
const ShareTest = React.lazy((_) => import("../pages/Mobile/ShareTest/"));
const AutumnRegPage = React.lazy((_) =>
  import("../pages/Mobile/MobAutumnTradingContest/AutumnRegPage")
);
const AutumnStartPage = React.lazy((_) =>
  import("../pages/Mobile/MobAutumnTradingContest/AutumnStartPage")
);
const AutumnRulePage = React.lazy((_) =>
  import("../pages/Mobile/MobAutumnTradingContest/AutumnRulePage")
);
const AutumnDetailRulePage = React.lazy((_) =>
  import("../pages/Mobile/MobAutumnTradingContest/AutumnDetailRulePage")
);
const AnswerPage = React.lazy((_) => import("../pages/Mobile/MobAnswer"));
const MobNewJobPage = React.lazy((_) =>
  import("../pages/Mobile/MobNewJobPage")
);
const MobGrowthPage = React.lazy((_) =>
  import("../pages/Mobile/MobGrowthPage")
);
const MobQlRankPage = React.lazy((_) =>
  import("../pages/Mobile/MobQlRankPage")
);
const MobQrsharePage = React.lazy((_) =>
  import("../pages/Mobile/MobQrsharePage")
);
const MobShareLottery = React.lazy((_) =>
  import("../pages/Mobile/MobShareLottery")
);
const MobQuest = React.lazy((_) => import("../pages/Mobile/MobQuest"));
const MobMOM = React.lazy((_) => import("../pages/Mobile/MobMOM"));
const MobBonus = React.lazy((_) => import("../pages/Mobile/MobBonus"));
const MobAward = React.lazy((_) => import("../pages/Mobile/MobAward"));
const MobEquityexc = React.lazy((_) => import("../pages/Mobile/MobEquityexc"));

const SimulationContTest = React.lazy(
  (
    _ //温州大赛
  ) => import("../pages/Mobile/MobSimulationContest/SimulationRegPage/Index")
);
const SimulationStartPage = React.lazy((_) =>
  import("../pages/Mobile/MobSimulationContest/SimulationStartPage/index")
);
const SimulationRulePage = React.lazy((_) =>
  import("../pages/Mobile/MobSimulationContest/SimulationRulePage/Index")
);
const SimulationDetailPage = React.lazy((_) =>
  import("../pages/Mobile/MobSimulationContest/SimulationDetailRulePage/Index")
);

const MobJiLinTradingRegPage = React.lazy(
  (
    _ //2023/10/19 校赛
  ) =>
    import(
      "../pages/Mobile/MobJiLinTradingContest/MobJiLinTradingRegPage/Index"
    )
);
const MobJiLinTradingStartPage = React.lazy((_) =>
  import(
    "../pages/Mobile/MobJiLinTradingContest/MobJiLinTradingStartPage/index"
  )
);
const MobJiLinTradingRulePage = React.lazy((_) =>
  import("../pages/Mobile/MobJiLinTradingContest/MobJiLinTradingRulePage/Index")
);
const MobJiLinTradingDetailPage = React.lazy((_) =>
  import(
    "../pages/Mobile/MobJiLinTradingContest/MobJiLinTradingDetailPage/Index"
  )
);

const MobAssistsCenterPage = React.lazy(
  (
    _ //app帮助中心
  ) => import("../pages/Mobile/MobAssistPage/MobAssistCenter/index")
);
const MobAssistsDetailPage = React.lazy((_) =>
  import("../pages/Mobile/MobAssistPage/MobAssistDetail/index")
);
const MobStaticPage = React.lazy((_) =>
  import("../pages/Mobile/MobStaticPage/index")
);

// 政策与隐私
const MobPolicyAbstract = React.lazy((_) =>
  import("../pages/Mobile/MobPolicyAgreement/MobPolicyAbstract/index")
);
const MobPolicyAgreenmentDisclaimer = React.lazy((_) =>
  import("../pages/Mobile/MobPolicyAgreement/MobDisclaimer/index")
);
const MobPolicyUserAgreement = React.lazy((_) =>
  import("../pages/Mobile/MobPolicyAgreement/MobUserAgreement/index")
);
const MobHSCommunityPrivacyPolicy = React.lazy((_) =>
  import("../pages/Mobile/MobPolicyAgreement/MobPrivacyPolicy/index")
);
const MobThirdPartyInstructions = React.lazy((_) =>
  import("../pages/Mobile/MobPolicyAgreement/MobThirdPartyInstructions/index")
);
const MobPersonalInformation = React.lazy((_) =>
  import("../pages/Mobile/MobPolicyAgreement/MobPersonalInformation/index")
);

const routerList = [
  {
    title: "微信分享测试",
    path: "/mobile/Sharetest",
    component: ShareTest,
    exact: true,
  },
  {
    title: "邀请好友",
    path: "/mobile/mobShare",
    component: MobShare,
    exact: true,
  },
  {
    title: "邀请记录",
    path: "/mobile/shareRecordList",
    component: ShareRecordList,
    exact: true,
  },
  {
    title: "活动规则",
    path: "/mobile/shareRulePage",
    component: ShareRulePage,
    exact: true,
  },
  {
    title: "好友分享注册",
    path: "/mobile/shareRegPage",
    component: ShareRegPage,
    exact: true,
  },
  {
    title: "好友分享注册",
    path: "/mobile/shareRegPagetype2",
    component: ShareRegPage,
    exact: true,
  },
  {
    title: "好友分享注册",
    path: "/mobile/shareRegPagetype3",
    component: ShareRegPage,
    exact: true,
  },
  {
    title: "联系客服",
    path: "/mobile/mobServicePage",
    component: MobServicePage,
    exact: true,
  },
  {
    title: "观察文章分享",
    path: "/mobile/mobArticleShare",
    component: MobArticleShare,
    exact: true,
  },
  {
    title: "TV分享",
    path: "/mobile/mobTVShare",
    component: MobTVShare,
    exact: true,
  },
  {
    title: "心得话题分享",
    path: "/mobile/mobTopicShare",
    component: MobTopicShare,
    exact: true,
  },
  {
    title: "用户协议",
    path: "/mobile/mobUserAgreement",
    component: MobUserAgreement,
    exact: true,
  },
  {
    title: "隐私政策",
    path: "/mobile/mobPrivacyPolicy",
    component: MobPrivacyPolicy,
    exact: true,
  },
  {
    title: "火象免责声明",
    path: "/mobile/mobdisclaimer",
    component: MobDisclaimer,
    exact: true,
  },
  {
    title: "“狗富贵，吾象旺”  共同富裕",
    path: "/mobile/mobgw",
    component: MobLotteryPage,
    exact: true,
  },
  {
    title: "“狗富贵，吾象旺”  共同富裕",
    path: "/mobile/mobgwrule",
    component: MobGfgRulePage,
    exact: true,
  },
  {
    title: "“狗富贵，吾象旺”  共同富裕",
    path: "/mobile/mobgwreg",
    component: MobGfgRegPage,
    exact: true,
  },
  {
    title: "上海火象趣数据科技有限公司",
    path: "/mobile/appdownload",
    component: MobAppDownLoad,
    exact: true,
  },
  {
    title: "上海火象趣数据科技有限公司",
    path: "/mobile/appdownloadoth",
    component: MobAppDownLoad,
    exact: true,
  },
  {
    title: "产品功能主要介绍",
    path: "/mobile/productfeatures",
    component: MobProductFeatures,
    exact: true,
  },
  {
    title: "S5交易大赛",
    path: "/mobile/mobtc",
    component: MobTradingContest,
    exact: true,
  },
  // {
  //   title: "S5交易大赛规则",
  //   path: "/mobile/mobtcrule",
  //   component: MobContestRulePage,
  //   exact: true,
  // },
  {
    title: "S5交易大赛榜单",
    path: "/mobile/mobtcranking",
    component: MobRankingPage,
    exact: true,
  },
  {
    title: "S6交易大赛",
    path: "/mobile/mobtcs6",
    component: MobContestStartPageS6,
    exact: true,
  },
  {
    title: "S6交易大赛规则",
    path: "/mobile/mobtcrules6",
    component: MobContestRulePageS6,
    exact: true,
  },
  {
    title: "S6交易大赛榜单",
    path: "/mobile/mobtcrankings6",
    component: MobRankingPageS6,
    exact: true,
  },
  {
    title: "S7交易大赛",
    path: "/mobile/mobtcs7",
    component: MobContestStartPageS7,
    exact: true,
  },
  {
    title: "S7交易大赛规则",
    path: "/mobile/mobtcrules7",
    component: MobContestRulePageS7,
    exact: true,
  },
  {
    title: "S7交易大赛榜单",
    path: "/mobile/mobtcrankings7",
    component: MobRankingPageS7,
    exact: true,
  },
  {
    title: "S8交易大赛",
    path: "/mobile/mobtcs8",
    component: MobContestStartPageS8,
    exact: true,
  },
  {
    title: "S8交易大赛规则",
    path: "/mobile/mobtcrules8",
    component: MobContestRulePageS8,
    exact: true,
  },
  {
    title: "S8交易大赛榜单",
    path: "/mobile/mobtcrankings8",
    component: MobRankingPageS8,
    exact: true,
  },
  {
    title: "你好创作者",
    path: "/mobile/mobframer",
    component: MobFramerPage,
    exact: true,
  },
  {
    title: "详细规则", //你好创作者
    path: "/mobile/mobframerule",
    component: MobFramerRulePage,
    exact: true,
  },
  {
    title: "上海火象趣数据科技有限公司守则",
    path: "/mobile/mobcommunityrule",
    component: MobCommunityRulePage,
    exact: true,
  },
  {
    title: "火象时刻",
    path: "/mobile/mobhxshowtime",
    component: MobHXShowtimePage,
    exact: true,
  },
  {
    title: "致用户",
    path: "/mobile/mobletter",
    component: MobLetter,
    exact: true,
  },
  {
    title: "致新朋友",
    path: "/mobile/mobnewuser",
    component: MobNewuser,
    exact: true,
  },
  {
    title: "金币提现关闭通知",
    path: "/mobile/mobcoinclose",
    component: MobCoinClose,
    exact: true,
  },
  {
    title: "东证期货开户",
    path: "/mobile/mobopen",
    component: MobOpen,
    exact: true,
  },
  {
    title: "奖励说明",
    path: "/mobile/mobopenrule",
    component: MobOpenRulePage,
    exact: true,
  },
  {
    title: "火象证书",
    path: "/mobile/mobcdesc",
    component: MobCertificateDesc,
    exact: true,
  },
  {
    title: "量化交易教程",
    path: "/mobile/mobdealcourse",
    component: MobDealCoursePage,
    exact: true,
  },
  {
    title: "火薪计划",
    path: "/mobile/huoxin",
    component: MobHuoXinPage,
    exact: true,
  },
  {
    title: "火象校园大使",
    path: "/mobile/campusambassador",
    component: CampusAPage,
    exact: true,
  },
  {
    title: "火象注册页",
    path: "/mobile/campusreg",
    component: CampusARegPage,
    exact: true,
  },
  {
    title: "火象投资家秋季联赛",
    path: "/mobile/autumnreg",
    component: AutumnRegPage,
    exact: true,
  },
  {
    title: "火象投资家秋季联赛",
    path: "/mobile/autostart", //大赛首页
    component: AutumnStartPage,
    exact: true,
  },
  {
    title: "火象投资家秋季联赛",
    path: "/mobile/auturule", //大赛规则
    component: AutumnRulePage,
    exact: true,
  },
  {
    title: "火象投资家秋季联赛", //大赛细则
    path: "/mobile/autudrule",
    component: AutumnDetailRulePage,
    exact: true,
  },
  {
    title: "答题",
    path: "/mobile/answer",
    component: AnswerPage,
  },
  {
    title: "金融名企全职/实习免费内推",
    path: "/mobile/newjob",
    component: MobNewJobPage,
    exact: true,
  },
  {
    title: "火象成长图鉴",
    path: "/mobile/growth",
    component: MobGrowthPage,
    exact: true,
  },
  {
    title: "秋季联赛获奖名单",
    path: "/mobile/qlrank",
    component: MobQlRankPage,
    exact: true,
  },
  {
    title: "上海火象趣数据科技有限公司",
    path: "/mobile/qrshare",
    component: MobQrsharePage,
    exact: true,
  },
  {
    title: "幸运转盘",
    path: "/mobile/mobsl",
    component: MobShareLottery,
    exact: true,
  },
  {
    title: "火象抽抽乐",
    path: "/mobile/quest",
    component: MobQuest,
    exact: true,
  },
  {
    title: "我的奖品",
    path: "/mobile/award",
    component: MobAward,
    exact: true,
  },
  {
    title: "权益兑换",
    path: "/mobile/equityexc",
    component: MobEquityexc,
    exact: true,
  },
  {
    title: "资金管理人",
    path: "/mobile/mom",
    component: MobMOM,
    exact: true,
  },
  {
    title: "持证补贴",
    path: "/mobile/bonus",
    component: MobBonus,
    exact: true,
  },
  {
    title: "模拟交易大赛", //模拟交易大赛
    path: "/mobile/simulationContest",
    component: SimulationContTest,
    exact: true,
  },
  {
    title: "模拟交易大赛", //模拟交易大赛首页
    path: "/mobile/simulstart",
    component: SimulationStartPage,
    exact: true,
  },
  {
    title: "大赛规则",
    path: "/mobile/simulrule", //大赛规则
    component: SimulationRulePage,
    exact: true,
  },
  {
    title: "评分细则",
    path: "/mobile/simuldetrule", //大赛规则
    component: SimulationDetailPage,
    exact: true,
  },
  {
    title: "模拟交易大赛", //模拟交易大赛
    path: "/mobile/tradingreg",
    component: MobJiLinTradingRegPage,
    exact: true,
  },
  {
    title: "模拟交易大赛", //模拟交易大赛首页
    path: "/mobile/tradingstart",
    component: MobJiLinTradingStartPage,
    exact: true,
  },
  {
    title: "大赛规则",
    path: "/mobile/tradingrule", //大赛规则
    component: MobJiLinTradingRulePage,
    exact: true,
  },
  {
    title: "评分细则",
    path: "/mobile/tradingdetail", //大赛规则
    component: MobJiLinTradingDetailPage,
    exact: true,
  },
  {
    title: "我的客服", //帮助中心
    path: "/mobile/AssistsCenter",
    component: MobAssistsCenterPage,
    exact: true,
  },
  {
    title: "详情", //详情
    path: "/mobile/AssistsDetail",
    component: MobAssistsDetailPage,
    exact: true,
  },
  {
    title: "", //静态页面  显示图片或网址
    path: "/mobile/mobstatic",
    component: MobStaticPage,
    exact: true,
  },
  {
    title: "隐私政策摘要",
    path: "/mobile/mobpolicyabstract",
    component: MobPolicyAbstract,
    exact: true,
  },
  {
    title: "免责声明",
    path: "/mobile/mobpolicyagreenmentdisclaimer",
    component: MobPolicyAgreenmentDisclaimer,
    exact: true,
  },
  {
    title: "用户协议",
    path: "/mobile/mobpolicyuseragreement",
    component: MobPolicyUserAgreement,
    exact: true,
  },
  {
    title: "隐私政策",
    path: "/mobile/mobhscommunityprivacypolicy",
    component: MobHSCommunityPrivacyPolicy,
    exact: true,
  },
  {
    title: "第三方服务说明",
    path: "/mobile/mobthirdpartyinstruction",
    component: MobThirdPartyInstructions,
    exact: true,
  },
  {
    title: "个人信息清单",
    path: "/mobile/mobpersonalinformation",
    component: MobPersonalInformation,
    exact: true,
  },
];

export default routerList;
