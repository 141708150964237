// import "antd/dist/antd.css";
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter, HashRouter } from "react-router-dom";
import './styles/resetCss.css';

ReactDOM.render(
  //如果需要配置基础路由
  // <BrowserRouter basename="/huosign">
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
)

reportWebVitals()
