import dayjs from "dayjs";

//复制
export const copyText = async (str = "") => {
  const area = document.createElement("textarea");
  area.value = str;
  area.setAttribute("readonly", "");
  area.style.position = "absolute";
  area.style.left = "-9999px";
  document.body.appendChild(area);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  area.select();
  document.execCommand("copy");
  document.body.removeChild(area);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

//H5页面阻止缩放
export const banXoom = () => {
  const meta = document.createElement("meta");
  meta.setAttribute("name", "viewport");
  meta.setAttribute("content", "width=375,user-scalable=no");
  document.head.appendChild(meta);
};

//取url参数
export const getQueryString = (name) => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"),
    r = window.location.search.substr(1).match(reg);
  if (r != null) return r[2];
  return null;
};
//判断是否为JSON
export const isJSON = (str) => {
  if (typeof str == "string") {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
};

//验证格式
export const validate = (value, type) => {
  var value = value.trim();
  if (type === "required") {
    return !!value;
  }
  if (type === "phone") {
    return /^1[3456789]\d{9}$/g.test(value);
  }
  if (type === "username") {
    return /^((?!\\|\/)[a-zA-Z0-9]){6,16}$/g.test(value);
  }
  if (type === "usernameForAdmin") {
    return /^(cd-|hz-){1}((?!\\|\/)[a-zA-Z0-9]){6,12}$/g.test(value);
  }
  if (type === "number") {
    return /\d+/g.test(value);
  }
  if (type === "email") {
    return /^(\w|\.|-|_)+@((\w+)\.){1,}?([a-zA-Z]+)$/g.test(value);
  }
  if (type === "code") {
    return /^\d{6}$/g.test(value);
  }
  // 至少6-12个字符，至少1个大写字母，1个小写字母和1个数字，其他可以是任意字符
  if (type === "password") {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{6,12}$/g.test(value);
  }
  if (type === "admin") {
    return /^(cd-|hz-)/g.test(value);
  }
  //number
  if (type === "-float") {
    return /^(0|(-?)(0\.[0-9]+|[1-9][0-9]+\.[0-9]+|[1-9]\d*))$/g.test(value);
  }
  if (type === "float") {
    return /^(0\.[0-9]+|[1-9][0-9]+\.[0-9]+|[1-9]\d*)$/g.test(value);
  }
  if (type === "decimal2") {
    return /^(\d+|\d+\.\d{1,2})$/g.test(value);
  }
  if (type === "sum5") {
    return /^\d{1,5}(\.|$)/g.test(value);
  }
  //number end
};
//生成随机password或username
//密码6-12 用户名6-16
export const getPwRandom = (_min = 6, _max = 12, type = "password") => {
  let str = "",
    range = _min,
    arr = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
  if (_max) {
    range = Math.round(Math.random() * (_max - _min)) + _min;
  }
  for (let i = 0; i < range; i++) {
    let pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
};
// js将数字转换成万、亿、万亿,并保留至小数点后一位
export const numberFormat = (value) => {
  var k = 10000,
    sizes = ["", "万", "亿", "万亿"],
    i;
  if (value < k) {
    return value;
  } else {
    i = Math.floor(Math.log(value) / Math.log(k));
    return getBit(value / Math.pow(k, i), 1) + sizes[i];
  }
};
export const getBit = (value, bit) => {
  let str = value.toString();
  let strIndex = str.indexOf(".");
  if (strIndex === -1) return str;
  str = str.substring(0, strIndex + bit + 1);
  return str;
};
// 将字符串中的网址找出来换成超链接
export const urlToLink = (str) => {
  let re =
    /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+/g;

  str = str.replace(re, function (website) {
    return "<a href='" + website + "' target='_blank'>" + website + "</a>";
  });
  return str;
};
/**
 * 判断是否是官方认证账号
 *
 * @returns `true`或`false`。
 */
export const isOfficialAccount = (uid) => {
  return (
    uid == 3306 ||
    uid == 71081 ||
    uid == 71132 ||
    uid == 265 ||
    uid == 246 ||
    uid == 337 ||
    uid == 74440 ||
    uid == 74441 ||
    uid == 71134 ||
    uid == 84656
  );
};
/**
 * 获取官方认证名称
 *
 * @returns 默认返回 `火象官方认证账号`。可返回 `ALPHA量化交易员`、`认证机构`、`火象官方认证账号`。
 */
export const getOfficialVerifyText = (uid, defaultText = "") => {
  if (uid == 265 || uid == 246 || uid == 337) {
    return "ALPHA量化交易员";
  }

  if (uid == 74440 || uid == 74441 || uid == 71134 || uid == 84656) {
    return "认证机构";
  }

  if (uid == 76043) {
    return "特邀创作者";
  }

  return isOfficialAccount(uid) ? "火象官方认证账号" : defaultText;
};
/**
 * 获取官方认证 logo 类型
 *
 * @returns 默认返回 `1`。`1`：黄色Vip，表示「火象官方认证账号」或「ALPHA量化交易员」；`2`：蓝色Vip，表示「认证机构」。
 */
export const getOfficialVerifyLogo = (uid) => {
  if (uid == 74440 || uid == 74441 || uid == 71134 || uid == 84656) {
    return 2;
  }
  if (
    uid == 3306 ||
    uid == 71081 ||
    uid == 71132 ||
    uid == 265 ||
    uid == 246 ||
    uid == 337
  ) {
    return 1;
  }
};
/**
 * 判断社区内容是否识别链接。被识别的链接将被替换为 `查看链接` 显示，并可以点击打开对应的网页
 *
 * @returns `true`或`false`。
 */
export const communityLinkableAccount = (uid) => {
  return (
    uid == 68499 || uid == 67445 || uid == 3306 || uid == 71081 || uid == 71132
  );
};
// 毫秒转秒
export function millisecond2Second(millisecond) {
  return Math.round(millisecond / 1000);
}
// 分，时，天，转秒
const DATE_CONFIG = {
  m: 60,
  h: 3600,
  d: 86400,
  daysOfYeay: 365,
};
// 计算时间戳之间的秒差
export function relativeSeconds(startStamp, endStamp) {
  if (endStamp) {
    return millisecond2Second(endStamp - startStamp + global.sysTimeInterval);
  } else {
    const endStamp = new Date().valueOf();
    return millisecond2Second(endStamp - startStamp);
  }
}
// 秒转时分秒
export function seconds2Date(seconds) {
  const dateObj = {
    h: 0,
    m: 0,
    s: 0,
  };
  if (seconds < DATE_CONFIG.m) {
    dateObj.s = seconds;
    return dateObj;
  } else if (seconds < DATE_CONFIG.h) {
    dateObj.m = Math.round(seconds / DATE_CONFIG.m);
    return dateObj;
  } else if (seconds < DATE_CONFIG.d) {
    dateObj.h = Math.round(seconds / DATE_CONFIG.h);
    return dateObj;
  }
  return dateObj;
}
/**
 * 人性化显示时间
 *
 * @param {number | string} date 时间（时间戳或者字符）默认是时间戳
 * @param {object} [options] 配置项(可选)
 * @param {boolean} options.isString 指定date类型：默认是false. false:数字时间戳 true: YYYY-MM-DD HH:mm:ss (ISO 8601 string)
 * @param {number} options.adjustVal 校正值。单位秒。即两时间相差{adjustVal}显示为刚刚。默认：0
 */
export function getHumanDate(date, options = {}) {
  const { isString, adjustVal = 0 } = options;
  const startTemp = date;
  const currDate = new Date().valueOf();
  const relativeS = relativeSeconds(startTemp, currDate);

  if (Math.abs(relativeS) <= adjustVal) {
    return "刚刚";
  }

  // 时间戳可能由服务器生成，会跟本地时间有误差。
  if (relativeS <= 0 && Math.abs(relativeS) > adjustVal) {
    return `未来 ${dayjs(startTemp).format("YYYY-MM-DD hh: mm")}`;
  }

  if (relativeS < DATE_CONFIG.m) {
    return "刚刚";
    // return `${relativeS}秒前`;
  } else if (relativeS < DATE_CONFIG.h) {
    return `${seconds2Date(relativeS).m}分钟前`;
  } else if (relativeS < DATE_CONFIG.d) {
    return `${seconds2Date(relativeS).h}小时前`;
  } else if (dayjs(startTemp).isSame(dayjs().subtract(1, "day"), "day")) {
    return `昨天 ${dayjs(startTemp).format("HH:mm")}`;
  } else if (dayjs(startTemp).isSame(dayjs().subtract(2, "day"), "day")) {
    return `前天 ${dayjs(startTemp).format("HH:mm")}`;
  } else if (dayjs(startTemp).isSame(dayjs(), "year")) {
    return dayjs(startTemp).format("MM-DD HH:mm");
  } else {
    return dayjs(startTemp).format("YYYY-MM-DD HH:mm");
  }
}
//数字日期格式转换为中文
export function convert(dateStr) {
  var dict = {
    0: "〇",
    1: "一",
    2: "二",
    3: "三",
    4: "四",
    5: "五",
    6: "六",
    7: "七",
    8: "八",
    9: "九",
    10: "十",
    11: "十一",
    12: "十二",
    13: "十三",
    14: "十四",
    15: "十五",
    16: "十六",
    17: "十七",
    18: "十八",
    19: "十九",
    20: "二十",
    21: "二十一",
    22: "二十二",
    23: "二十三",
    24: "二十四",
    25: "二十五",
    26: "二十六",
    27: "二十七",
    28: "二十八",
    29: "二十九",
    30: "三十",
    31: "三十一",
  };
  var date = dateStr.split("-"),
    yy = date[0],
    mm = date[1],
    dd = date[2];

  var yearStr = dict[yy[0]] + dict[yy[1]] + dict[yy[2]] + dict[yy[3]] + "年",
    monthStr = dict["" + Number(mm)] + "月",
    dayStr = dict[dd];

  return yearStr + monthStr + dayStr;
}
export const delayWait = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export function displayUSD(text, n = 2) {
  if (isNaN(text)) return "";
  return parseFloat(text).toLocaleString("en-US", {
    minimumFractionDigits: n,
    maximumFractionDigits: n,
  });
}
// 判断是不是json
export const isJson = (str) => {
  // if(typeof str) return false;
  try {
    const object = JSON.parse(str);
    if (object && typeof object === "object") {
      return true;
    } else {
      return false;
    }
  } catch (e) {}
};
const PREFIX = "@HS-";
// 设置指定键名的值
export const setStorage = (key, value, type = 0) => {
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  if (!type) {
    // sessionStorage
    sessionStorage.setItem(`${PREFIX}${key}`, value);
  } else {
    // localStorage
    localStorage.setItem(`${PREFIX}${key}`, value);
  }
};
// 获取指定键名的值
export const getStorage = (key, type = 0) => {
  let value = !type
    ? sessionStorage.getItem(`${PREFIX}${key}`)
    : localStorage.getItem(`${PREFIX}${key}`);
  return isJson(value) ? JSON.parse(value) : value;
};
// 清除本地存储内容
export const clearStorage = (type = 0) => {
  if (!type) {
    sessionStorage.clear();
  } else {
    localStorage.clear();
  }
};

//处理交易大赛时间
export const dealtRankingTime = () => {
  const toDayDate = new Date();
  const lastDayDate = new Date(
    toDayDate.getFullYear(),
    toDayDate.getMonth() + 1,
    0
  );

  const today = `${toDayDate.getFullYear()}/${(
    "0" +
    (toDayDate.getMonth() + 1)
  ).slice(-2)}/${("0" + toDayDate.getDate()).slice(-2)} ${(
    "0" + toDayDate.getHours()
  ).slice(-2)}:${("0" + toDayDate.getMinutes()).slice(-2)}:${(
    "0" + toDayDate.getSeconds()
  ).slice(-2)}`;

  const prevDay = `${toDayDate.getFullYear()}/${(
    "0" +
    (toDayDate.getMonth() + 1)
  ).slice(-2)}/06 00:00:00`;

  const lastDay = `${lastDayDate.getFullYear()}/${(
    "0" +
    (lastDayDate.getMonth() + 1)
  ).slice(-2)}/${lastDayDate.getDate()} 17:00:00`;

  let { nextMonthStr, monthSettlement } = getNextMonth();

  return {
    toDayDate,
    today,
    lastDay,
    prevDay,
    nextMonth: nextMonthStr,
    monthSettlement, //本月的结算时间
    prevMonth: getLastDayOfLastMonth(),
  };
};

//获取下个月一号的日期
const getNextMonth = () => {
  let date = new Date();
  let year = date.getFullYear();
  let nextMonthStr = "";
  let next_month = "";
  if (date.getMonth() === 11) {
    nextMonthStr = Number(year) + 1 + "/01" + "/01" + " 00:00:00";
  } else {
    next_month =
      parseInt(date.getMonth() + 2) < 10
        ? "0" + parseInt(date.getMonth() + 2)
        : parseInt(date.getMonth() + 2);
    nextMonthStr = year + "/" + next_month + "/01" + " 00:00:00";
  }

  // nextMonthStr = year + "/" + next_month + "/01";

  let monthSettlement =
    year +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + (date.getDate() + 1)).slice(-2) +
    " 17:00:00";

  return { nextMonthStr, monthSettlement };
};

//上个月的结算时间
function getLastDayOfLastMonth() {
  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth(); // 从0开始的数字，表示月份

  // 如果当前月份是0（1月），则上个月是11（12月），年份减1
  if (month === 0) {
    month = 11;
    year -= 1;
  }

  const lastDayOfLastMonth = new Date(year, month, 0);

  return `${lastDayOfLastMonth.getFullYear()}-${(
    "0" +
    (lastDayOfLastMonth.getMonth() + 1)
  ).slice(-2)}-${("0" + lastDayOfLastMonth.getDate()).slice(-2)} 17:00:00`;
}

export function getFirstDayOfWeek() {
  const today = new Date();
  const dayOfWeek = today.getDay() || 7; // 将周日从0转换为7
  const diff = dayOfWeek - 1; // 计算当前日期到周一的差值
  const firstDayOfWeek = new Date(today);
  firstDayOfWeek.setDate(today.getDate() - diff); // 回退到周一

  const year = firstDayOfWeek.getFullYear();
  const month = firstDayOfWeek.getMonth() + 1; // 月份是从0开始的，所以加1
  const day = firstDayOfWeek.getDate();

  return {
    year: String(year).slice(-2),
    month: ("0" + month).slice(-2),
    day: ("0" + day).slice(-2),
  };
}

//获取路由名称
export function getPageName(pathname, routers) {
  let webName = "上海火象趣数据科技有限公司";

  if (!pathname || !routers) return webName;

  let filtersList = routers.filter((item) => pathname.includes(item.path));

  for (let item of filtersList) {
    if (item.path === pathname) {
      webName = item.title;
      break;
    }

    if (item?.children) {
      webName = getPageName(pathname, item.children);
    }
  }

  return webName;
}
