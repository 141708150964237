import React from "react";

import ruanwenPopularize from "./ruanwenPopularize";

const routerList = [
  //推广
  ...ruanwenPopularize,
  {
    title: "邀请好友立减79元参赛", //砍一刀
    path: "/trader/introduction-d",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizeDiscountPage/index")
    ),
    exact: true,
  },
  {
    title: "兑换码", //我的兑换码
    path: "/trader/introduction-cdkey",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PopularizeCDkeyPage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //大赛注册登录页 (推广)
    path: "/trader/register",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderRegisterPage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //大赛报名页
    path: "/trader/apply",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderApplyPage/index")
    ),
    exact: true,
  },
  {
    title: "获奖名单",
    path: "/trader/winner",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderContestWinnerList/index")
    ),
    exact: true,
  },
  {
    title: "选择奖励", //获奖=>选择奖励
    path: "/trader/prize",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderContestPrize/index")
    ),
    exact: true,
  },
  {
    title: "领奖历史", //获奖=>奖励 =>领奖历史
    path: "/trader/prize-history",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderContestPrizeHistory/index")
    ),
    exact: true,
  },
  {
    title: "确认收款信息", //获奖=>确认收款信息
    path: "/trader/prize-confirm",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderConfirmPaymentInfo/index")
    ),
    exact: true,
  },
  {
    title: "编辑收款信息", //获奖=>确认收款信息 =>编辑收款信息
    path: "/trader/prize-edit",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderEditPaymentInfo/index")
    ),
    exact: true,
  },
  {
    title: "", //获奖=>确认收款信息 =>领取成功 /  报名成功
    path: "/trader/success",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderPrizeResult/index")
    ),
    exact: true,
  },
  {
    title: "解锁年度科学家榜单",
    path: "/trader/unlocking",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/UnlockingScientist/index")
    ),
    exact: true,
  },
  {
    title: "", //支付成功
    path: "/trader/payment",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/PaymentSuccess/index")
    ),
    exact: true,
  },
  {
    title: "邀请记录",
    path: "/trader/invitation-record",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderInvitationRecord/index")
    ),
    exact: true,
  },
  {
    title: "规则说明",
    path: "/trader/rule",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderRuleDescription/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛",
    path: "/trader/traderContest",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderContestProject/index")
    ),
    children: [
      {
        title: "百万交易员大赛-首页",
        path: "/trader/traderContest/home",
        component: React.lazy((_) =>
          import(
            "../pages/MillionTraderContest/TraderContestProject/TraderContestHome/index"
          )
        ),
        exact: true,
      },
      {
        title: "百万交易员大赛-我的权益",
        path: "/trader/traderContest/interest",
        component: React.lazy((_) =>
          import(
            "../pages/MillionTraderContest/TraderContestProject/TraderContestMyInterest/index"
          )
        ),
        exact: true,
      },
      {
        title: "百万交易员大赛-排行榜",
        path: "/trader/traderContest/ranking",
        component: React.lazy((_) =>
          import(
            "../pages/MillionTraderContest/TraderContestProject/TraderContestRankingList/index"
          )
        ),
        exact: true,
      },
      {
        title: "百万交易员大赛-邀请好友",
        path: "/trader/traderContest/invite",
        component: React.lazy((_) =>
          import(
            "../pages/MillionTraderContest/TraderContestProject/TraderContestInvitation/index"
          )
        ),
        exact: true,
      },
    ],
  },
  {
    title: "火力大转盘",
    path: "/trader/turntable",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TarderTurntableActivePage/index")
    ),
    exact: true,
  },
  {
    title: "乾坤未定 转盘见真火",
    path: "/trader/turntable-wukong",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest2/TarderTurntableActivePage/index")
    ),
    exact: true,
  },
  {
    title: "幸运刮刮乐",
    path: "/trader/scratch",
    component: React.lazy((_) =>
      import(
        "../pages/MillionTraderContest/TarderScratchTicketActivePage/index"
      )
    ),
    exact: true,
  },
  {
    title: "抽奖记录",
    path: "/trader/drawRecord",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderDrawRecord/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛",
    path: "/trader/megagame",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContest/TraderPromotionPage/index")
    ),
    children: [
      {
        title: "百万交易员大赛",
        path: "/trader/megagame/landing1",
        component: React.lazy((_) =>
          import(
            "../pages/MillionTraderContest/TraderPromotionPage/component/LandingPage1"
          )
        ),
        exact: true,
      },
      {
        title: "百万交易员大赛",
        path: "/trader/megagame/landing2",
        component: React.lazy((_) =>
          import(
            "../pages/MillionTraderContest/TraderPromotionPage/component/LandingPage2"
          )
        ),
        exact: true,
      },
      {
        title: "百万交易员大赛",
        path: "/trader/megagame/landing3",
        component: React.lazy((_) =>
          import(
            "../pages/MillionTraderContest/TraderPromotionPage/component/LandingPage3"
          )
        ),
        exact: true,
      },
      {
        title: "百万交易员大赛",
        path: "/trader/megagame/landing4",
        component: React.lazy((_) =>
          import(
            "../pages/MillionTraderContest/TraderPromotionPage/component/LandingPage4"
          )
        ),
        exact: true,
      },
      {
        title: "百万交易员大赛",
        path: "/trader/megagame/landing5",
        component: React.lazy((_) =>
          import(
            "../pages/MillionTraderContest/TraderPromotionPage/component/LandingPage5"
          )
        ),
        exact: true,
      },
      {
        title: "百万交易员大赛",
        path: "/trader/megagame/landing6",
        component: React.lazy((_) =>
          import(
            "../pages/MillionTraderContest/TraderPromotionPage/component/LandingPage6"
          )
        ),
        exact: true,
      },
    ],
  },
];

export default routerList;
