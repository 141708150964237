import React from "react";
import { Redirect } from "react-router-dom";
import mobileRouter from "./mobRouter"; //移动端活动页路由
import millionTrader from "./millionTrader";
import millionTraderPC from "./millionTraderPC";

const HomePage = React.lazy((_) => import("../pages/HomePage/index"));
const NotFoundPage = React.lazy((_) => import("../pages/404.js"));
const UserPolicy = React.lazy((_) => import("../pages/UserPolicy"));
const PrivacyPage = React.lazy((_) =>
  import("../pages/UserPolicy/PrivacyPage")
);
const ProtocolPage = React.lazy((_) =>
  import("../pages/UserPolicy/ProtocolPage")
);
const CooperationPage = React.lazy((_) => import("../pages/CooperationPage"));
const AboutUsPage = React.lazy((_) => import("../pages/AboutUsPage"));
const AboutUsPage1 = React.lazy((_) => import("../pages/AboutUsPage1"));
const MobileScreen = React.lazy((_) => import("../pages/Mobile"));
const TestHeLoginPage = React.lazy((_) => import("../pages/TestHeLoginPage"));
const MakeStaticPage = React.lazy((_) => import("../pages/MakeStaticPage"));
const MakeQrCode = React.lazy((_) => import("../pages/MakeQrCode"));

const MillionTraderContest = React.lazy((_) =>
  import("../pages/MillionTraderContest")
);

const MillionTraderContestPC = React.lazy((_) =>
  import("../pages/MillionTraderContestPC")
);

const ApiDocumentation = React.lazy((_) => import("../pages/ApiDocumentation"));

const router = [
  {
    title: "上海火象趣数据科技有限公司",
    path: "/",
    exact: true,
    component: HomePage,
  },
  {
    title: "合作招商",
    path: "/cooperationPage",
    component: CooperationPage,
  },
  {
    title: "关于我们",
    path: "/aboutus",
    component: AboutUsPage,
  },
  {
    title: "关于我们 ",
    path: "/aboutUsPage1",
    component: AboutUsPage1,
  },
  {
    title: "用户政策",
    path: "/userPolicy",
    component: UserPolicy,
    children: [
      {
        title: "隐私政策",
        path: "/userPolicy/privacy",
        component: PrivacyPage,
        exact: true,
      },
      {
        title: "用户协议",
        path: "/userPolicy/protocol",
        component: ProtocolPage,
        exact: true,
      },
    ],
  },
  {
    title: "测试获取token",
    path: "/testlogintotoken",
    component: TestHeLoginPage,
  },
  {
    title: "制作APP内置活动页面",
    path: "/makepage",
    component: MakeStaticPage,
  },
  {
    title: "生成二维码",
    path: "/makeqrcode",
    component: MakeQrCode,
  },
  // 活动页
  {
    title: "",
    path: "/mobile",
    component: MobileScreen,
    children: mobileRouter,
  },
  //百万交易员大赛
  {
    title: "",
    path: "/trader",
    component: MillionTraderContest,
    children: millionTrader,
  },
  //百万交易员大赛 PC
  {
    title: "",
    path: "/huosign-trader",
    component: MillionTraderContestPC,
    children: millionTraderPC,
  },
  {
    title: "火象交易API",
    path: "/huosign-api",
    component: ApiDocumentation,
  },
  {
    title: "404",
    path: "*",
    component: NotFoundPage,
  },
];

export default router;
