import React from "react";

const routerList = [
  {
    title: "百万交易员大赛", //首页
    path: "/huosign-trader/home",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContestPC/TraderContestHome/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //排名页
    path: "/huosign-trader/ranking",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContestPC/TraderContestRankingList/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //我的权益
    path: "/huosign-trader/interest",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContestPC/TraderContestMyInterest/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //邀请记录
    path: "/huosign-trader/invite",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContestPC/TraderContestInvitation/index")
    ),
    exact: true,
  },
  {
    title: "火力大转盘", //活动转盘
    path: "/huosign-trader/turntable",
    component: React.lazy((_) =>
      import(
        "../pages/MillionTraderContestPC/TraderContestActivities/TraderContestTurntablePage"
      )
    ),
    exact: true,
  },
  {
    title: "幸运刮刮乐", //活动刮刮乐
    path: "/huosign-trader/scratchticket",
    component: React.lazy((_) =>
      import(
        "../pages/MillionTraderContestPC/TraderContestActivities/TraderContestScratchTicketPage/index"
      )
    ),
    exact: true,
  },
  {
    title: "活动中心", //活动中心
    path: "/huosign-trader/activity",
    component: React.lazy((_) =>
      import(
        "../pages/MillionTraderContestPC/TraderContestActivities/TraderContestActivityCenter/index"
      )
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //大赛报名页
    path: "/huosign-trader/apply",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContestPC/TraderApplyPage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛", //大赛推广页
    path: "/huosign-trader/promotion",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContestPC/TraderPromotionPage/index")
    ),
    exact: true,
  },
  {
    title: "百万交易员大赛-获奖名单", //获奖名单
    path: "/huosign-trader/winner",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContestPC/TraderContestWinnerList/index")
    ),
    exact: true,
  },
  {
    title: "选择奖励", //获奖=>选择奖励
    path: "/huosign-trader/winner-prize",
    component: React.lazy((_) =>
      import("../pages/MillionTraderContestPC/TraderContestPrize/index")
    ),
    exact: true,
  },
];

export default routerList;
